import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles.css';
import logo from '../images/LOGOAICDI.png'; // Ajusta la ruta si es necesario
import angel from '../images/angelindepen.jpeg'; // Ajusta la ruta si es necesario

const Construccion = () => {
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setProgress((prevProgress) => {
                if (prevProgress >= 60) {
                    return 0; // Reinicia el progreso a 0% cuando llega al 60%
                }
                return prevProgress + 1; // Incrementa el progreso en 1%
            });
        }, 300); // Actualiza cada 300ms

        return () => clearInterval(interval); // Limpia el intervalo cuando el componente se desmonta
    }, []);

    return (
        <div className="contenedor">
            <header className="header">
                <div className="navbar-container">
                    <img src={logo} alt="Logo Financiera" className="header-logo" />
                </div>
            </header>

            <div className="imagen-angel">
                <img src={angel} alt="Angel" />
                <div className="texto">PÁGINA EN CONSTRUCCIÓN
                <p>contacto@crecifin.com.mx</p>
                </div>
                

            </div>

            <footer className="footer">
                <p>&copy; 2024 ASESORIA INTEGRAL PARA EL CRECIMIENTO Y DESARROLLO INDUSTRIAL. </p>
                <div className="footer-links">

                </div>
            </footer>
        </div>
    );
};

export default Construccion;
